import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {useState} from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import {saveAs} from 'file-saver';
import SubmitDialog from './SubmitDialog';
import {Outlet} from 'react-router-dom';
import shuffle from './MusicOrderShuffler';
import React from 'react';
import TextField from '@mui/material/TextField';
import RatingRadio from './RatingRadio';

function Group3Form(props) {
  const [haydnFormFields, setHaydnFormFields] = useState({
    'version1_rating': 0,
    'version2_rating': 0,
    'version3_rating': 0,
    'version4_rating': 0,
    'version5_rating': 0,
  });

  const [gabrieliFormFields, setGabrieliFormFields] = useState({
    'version1_rating': 0,
    'version2_rating': 0,
    'version3_rating': 0,
    'version4_rating': 0,
    'version5_rating': 0,
  });

  const [otherInfoFormFields, setOtherInfoFormFields] = useState({
    'primary_instrument': '',
  });

  const [dialogStatus, setDialogStatus] = useState({
    'open': false,
    'status': 'incomplete',
  });

  const changeFormFields = (setFormFields) => {
    return (name, value) =>
      setFormFields((prevState) => ({
        ...prevState,
        [name]: value,
      }));
  };

  const handleDialogClose = () => {
    setDialogStatus({
      'open': false,
      'status': dialogStatus.status,
    });
  };

  const saveJson = () => {
    let jsonData = {};
    props.haydnArray.forEach((e, i) => {
      jsonData[e] = haydnFormFields[`version${i + 1}_rating`];
    });
    props.gabrieliArray.forEach((e, i) => {
      jsonData[e] = gabrieliFormFields[`version${i + 1}_rating`];
    });
    Object.assign(jsonData, otherInfoFormFields);
    saveAs(new Blob([JSON.stringify(jsonData, null, 2)],
      {type: 'application/json'}), `form_result_${Date.now()}`);
  };

  const onSubmit = () => {
    if (Object.values(haydnFormFields).includes(0) ||
      Object.values(gabrieliFormFields).includes(0) ||
      Object.values(otherInfoFormFields).includes('')) {
      setDialogStatus({
        'open': true,
        'status': 'incomplete',
      });
    } else {
      setDialogStatus({
        'open': true,
        'status': 'complete',
      });
      saveJson();
    }
  };

  return (
    <Container maxWidth="sm" style={{marginTop: '36px'}}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={8}>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={4}>
          <Typography variant="h3" component="div"
                      style={{textAlign: 'center'}}>
            Dissimilarity Rating
          </Typography>
          <Typography variant="body1" component="div" color="#888888">
            In this experiment, you will be asked to rate the dissimilarity of
            timbre in 10 music clips.
            These clips have A - B - A' forms, indicated with videos.
            <br/>
            For each section, there are 5 versions of one same excerpt, with
            different processing on A'.
            <br/>
            You are required to rate how different of the timbre between the A
            and the A' part on each version.
            If A is totally same as A', the rating should be 1,
            if totally different, the rating should be 7.
            <br/>
            After rating these 10 clips, you will be asked to provide some
            personal background information.
          </Typography>
          <Typography variant="subtitle1" component="div">
            Estimated time: 15 minutes
          </Typography>
        </Stack>
        <Divider/>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}>
          <Typography variant="h4" component="div"
                      style={{alignSelf: 'center'}}>
            Section 1
          </Typography>
          <Stack direction="column" justifyContent="center" alignItems="center"
                 spacing={6}>
            {Array.from({length: 5}, (_, i) => i + 1).map((i) =>
              <Stack direction="column" alignItems="center" spacing={2}>
                <video width="100%" controls>
                  <source src={`/audios/${props.haydnArray[i - 1]}.mp4`}
                          type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
                <RatingRadio name={`version${i}_rating`}
                             value={haydnFormFields[`version${i}_rating`]}
                             setValue={changeFormFields(setHaydnFormFields)}
                             label={`Version ${i}`}/>
              </Stack>,
            )}
          </Stack>
        </Stack>
        <Divider>Section 1 Finished</Divider>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}>
          <Typography variant="h4" component="div"
                      style={{alignSelf: 'center'}}>
            Section 2
          </Typography>
          <Stack direction="column" justifyContent="center" alignItems="center"
                 spacing={6}>
            {Array.from({length: 5}, (_, i) => i + 1).map((i) =>
              <Stack direction="column" alignItems="center" spacing={2}>
                <video width="100%" controls>
                  <source src={`/audios/${props.gabrieliArray[i - 1]}.mp4`}
                          type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
                <RatingRadio name={`version${i}_rating`}
                             value={gabrieliFormFields[`version${i}_rating`]}
                             setValue={changeFormFields(setGabrieliFormFields)}
                             label={`Version ${i}`}/>
              </Stack>,
            )}
          </Stack>
        </Stack>
        <Divider>Section 2 Finished</Divider>
        <TextField
          label="Your primary instrument for performance"
          fullWidth
          error={!otherInfoFormFields.primary_instrument}
          required
          helperText={!otherInfoFormFields.primary_instrument &&
          'Cannot be empty.'}
          name="primary_instrument"
          value={otherInfoFormFields.primary_instrument}
          onChange={(event) => {
            setOtherInfoFormFields({
              primary_instrument: event.target.value,
            });
          }}
        />
        <Button variant="contained" endIcon={<SendIcon/>} size="large"
                color="secondary"
                onClick={onSubmit}>
          Submit
        </Button>
        <SubmitDialog open={dialogStatus.open} status={dialogStatus.status}
                      onClose={handleDialogClose}/>
      </Stack>
      <Outlet/>
    </Container>
  );
}

export default function Group3FormPage() {
  const haydnVideoArray = [
    'Haydn_1',
    'Haydn_2',
    'Haydn_3',
    'Haydn_4',
    'Haydn_5',
  ];
  const gabrieliVideoArray = [
    'Gabrieli_1',
    'Gabrieli_2',
    'Gabrieli_3',
    'Gabrieli_4',
    'Gabrieli_5',
  ];

  let shuffledHaydnVideoArray = shuffle(haydnVideoArray);
  let shuffledGabrieliVideoArray = shuffle(gabrieliVideoArray);

  return (
    <Container maxWidth="md" style={{marginTop: '36px', marginBottom: '36px'}}>
      <Group3Form
        haydnArray={shuffledHaydnVideoArray}
        gabrieliArray={shuffledGabrieliVideoArray}/>
      <Outlet/>
    </Container>
  );
}