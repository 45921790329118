import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function IntroductionPage () {
  return (
    <Container maxWidth="sm" style={{marginTop: '36px'}}>
      <Typography variant="h4" component="div" style={{marginRight: '24px'}}>
        Experiment Introduction
      </Typography>
    </Container>
  );
}