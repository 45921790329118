import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Group2FormPage from './Group2FormPage';
import IntroductionPage from './IntroductionPage';
import Group3FormPage from './Group3FormPage';
import TrainingPage from './TrainingPage';
import DemoPage from './DemoPage';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App/>}>
          <Route path="/introduction" element={<IntroductionPage/>}/>
          <Route path="/training" element={<TrainingPage/>}/>
          <Route path="/form" element={<Group3FormPage/>}/>
          <Route path="/demo" element={<DemoPage/>}/>
          <Route path="/group2" element={<Group2FormPage/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
