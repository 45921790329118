import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export default function TrainingPage () {
  return (
    <Container maxWidth="sm" style={{marginTop: '36px'}}>
      <Stack direction="column" justifyContent="center" alignItems="center"
             spacing={8}>
        <Typography variant="h4" component="div" style={{textAlign: "center"}}>
          Exploring Timbre
        </Typography>
        <Typography variant="body1" component="div" color="#888888">
          In this training section, you will listen to some very short clips
          of two excerpts. Each clip has different processing, so with a
          different timbre.
          <br/>
          All these clips include the full range of timbres, so the largest
          dissimilarity will be between the two most different of those sounds.
          <br/>
          After finishing this training section, you could start the experiment.
        </Typography>
        <Stack direction="column" justifyContent="center" alignItems="center"
               spacing={3}>
          <Typography variant="h6">Example 1</Typography>
          <audio controls>
            <source src={'/audios/Haydn_training.mp3'} type="audio/mp3"/>
            Your browser does not support the audio element.
          </audio>
        </Stack>
        <Stack direction="column" justifyContent="center" alignItems="center"
               spacing={3}>
          <Typography variant="h6">Example 2</Typography>
          <audio controls>
            <source src={'/audios/Gabrieli_training.mp3'} type="audio/mp3"/>
            Your browser does not support the audio element.
          </audio>
        </Stack>
      </Stack>
    </Container>
  );
}