import React from 'react';
import {Outlet, Link as RouterLink} from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {deepPurple, indigo} from '@mui/material/colors';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Stack from '@mui/material/Stack';

let globalTheme = createTheme({
  palette: {
    primary: {
      main: deepPurple[100],
    },
    secondary: {
      main: indigo[100],
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={globalTheme}>
      <div className="App">
        <AppBar position="static">
          <Toolbar>
              <Stack direction="row" justifyContent="center" alignItems="center" spacing={3}>
                <Link component={RouterLink} to="/" underline="none" color="inherit">
                  <Typography variant="h6" component="div">
                    MUMT-616 Forms
                  </Typography>
                </Link>
                {/*<Link component={RouterLink} to="/introduction" underline="none" color="inherit">*/}
                {/*  <Typography variant="body1">*/}
                {/*    Introduction*/}
                {/*  </Typography>*/}
                {/*</Link>*/}
                <Link component={RouterLink} to="/training" underline="none" color="inherit">
                  <Typography variant="body1">
                    Training
                  </Typography>
                </Link>
                <Link component={RouterLink} to="/form" underline="none" color="inherit">
                  <Typography variant="body1">
                    Form
                  </Typography>
                </Link>
                <Link component={RouterLink} to="/demo" underline="none" color="inherit">
                  <Typography variant="body1">
                    Demo
                  </Typography>
                </Link>
                <Link component={RouterLink} to="/group2" underline="none" color="inherit" hidden>
                  <Typography variant="body1">
                    Group 2 Form
                  </Typography>
                </Link>
              </Stack>
          </Toolbar>
        </AppBar>
        <Outlet/>
      </div>
    </ThemeProvider>
  );
}

export default App;
