import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {useState} from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import SubmitDialog from './SubmitDialog';
import {Outlet} from 'react-router-dom';
import shuffle from './MusicOrderShuffler';
import React from 'react';
import RatingRadio from './RatingRadio';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

function DemoPageForm(props) {
  const [gabrieliFormFields, setGabrieliFormFields] = useState({
    'version1_rating': 0,
    'version2_rating': 0,
    'version3_rating': 0,
    'version4_rating': 0,
    'version5_rating': 0,
  });

  const [dialogStatus, setDialogStatus] = useState({
    'open': false,
    'status': 'incomplete',
  });

  const [showChart, setShowChart] = useState(false);
  const [chartDataArray, setChartDataArray] = useState([0, 0, 0, 0, 0]);
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Dissimilarity Rating Chart',
        font: {size: 28, weight: 400},
      },
    },
    scales: {
      y: {
        min: 1,
        max: 7,
        title: {
          display: true,
          text: 'Rating',
          font: {size: 18},
        },
        ticks: {
          font: {size: 16},
        },
      },
      x: {
        title: {
          display: true,
          text: 'Version',
          font: {size: 18},
        },
        ticks: {
          font: {size: 16},
        },
      },
    },
  };
  const chartData = {
    labels: [
      'LP: 1000 Hz',
      'LP: 2000 Hz',
      'Original',
      'HP: 800 Hz',
      'HP: 1600 Hz'],
    datasets: [
      {
        label: 'Gabrieli',
        data: chartDataArray,
        borderColor: 'rgb(209, 196, 233)',
        backgroundColor: 'rgba(209, 196, 233, 0.5)',
      },
    ],
  };

  const changeFormFields = (setFormFields) => {
    return (name, value) =>
      setFormFields((prevState) => ({
        ...prevState,
        [name]: value,
      }));
  };

  const handleDialogClose = () => {
    setDialogStatus({
      'open': false,
      'status': dialogStatus.status,
    });
  };

  const onSubmit = () => {
    if (Object.values(gabrieliFormFields).includes(0)) {
      setDialogStatus({
        'open': true,
        'status': 'incomplete',
      });
    } else {
      setDialogStatus({
        'open': true,
        'status': 'complete',
      });
      let trueOrderObject = {};
      Object.values(gabrieliFormFields).forEach((item, i) => {
        trueOrderObject[props.gabrieliArray[i]] = item;
      });
      let trueOrderArray = [];
      [
        'Gabrieli_4',
        'Gabrieli_5',
        'Gabrieli_1',
        'Gabrieli_2',
        'Gabrieli_3'].forEach((item) => {
        trueOrderArray.push(trueOrderObject[item]);
      });
      trueOrderArray = trueOrderArray.map((item) => parseInt(item));
      setChartDataArray(trueOrderArray);
      setShowChart(true);
    }
  };

  return (
    <Container maxWidth="xl" style={{marginTop: '36px'}}>
      <Container maxWidth="sm">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={8}>
          <Stack direction="column" justifyContent="center" alignItems="center"
                 spacing={8}>
            <Typography variant="h3" component="div"
                        style={{textAlign: 'center'}}>
              Exploring Timbre
            </Typography>
            <Stack direction="column" justifyContent="center"
                   alignItems="center"
                   spacing={3}>
              <Typography variant="h6">Training Example</Typography>
              <audio controls>
                <source src={'/audios/Gabrieli_training.mp3'} type="audio/mp3"/>
                Your browser does not support the audio element.
              </audio>
            </Stack>
          </Stack>
          <Divider/>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}>
            <Typography variant="h3" component="div"
                        style={{textAlign: 'center'}}>
              Dissimilarity Rating
            </Typography>
          </Stack>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}>
            <Stack direction="column" justifyContent="center"
                   alignItems="center"
                   spacing={6}>
              {Array.from({length: 5}, (_, i) => i + 1).map((i) =>
                <Stack direction="column" alignItems="center" spacing={2}>
                  <video width="100%" controls>
                    <source src={`/audios/${props.gabrieliArray[i - 1]}.mp4`}
                            type="video/mp4"/>
                    Your browser does not support the video tag.
                  </video>
                  <RatingRadio name={`version${i}_rating`}
                               value={gabrieliFormFields[`version${i}_rating`]}
                               setValue={changeFormFields(
                                 setGabrieliFormFields)}
                               label={`Version ${i}`}/>
                </Stack>,
              )}
            </Stack>
          </Stack>
          <Button variant="contained" endIcon={<SendIcon/>} size="large"
                  color="secondary"
                  onClick={onSubmit}>
            Submit
          </Button>
          <SubmitDialog open={dialogStatus.open} status={dialogStatus.status}
                        onClose={handleDialogClose}/>
        </Stack>
      </Container>
      <Divider style={{marginTop: '4em', marginBottom: '4em'}}/>
      {showChart &&
      <Line key="line-chart" data={chartData} options={chartOptions}/>}
      <Outlet/>
    </Container>
  );
}

export default function DemoPage() {
  const gabrieliVideoArray = [
    'Gabrieli_1',
    'Gabrieli_2',
    'Gabrieli_3',
    'Gabrieli_4',
    'Gabrieli_5',
  ];

  let shuffledGabrieliVideoArray = shuffle(gabrieliVideoArray);

  return (
    <Container maxWidth="md" style={{marginTop: '36px', marginBottom: '36px'}}>
      <DemoPageForm
        gabrieliArray={shuffledGabrieliVideoArray}/>
      <Outlet/>
    </Container>
  );
}