import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';

export default function RatingRadio(props) {
  const [error, setError] = React.useState(true);

  const handleRadioChange = (event) => {
    props.setValue(props.name, event.target.value);
    setError(false);
  };

  return (
    <FormControl
      component="fieldset"
      error={error}
      variant="standard">
      <FormLabel color="success">{props.label}</FormLabel>
      <RadioGroup
        aria-label={props.name}
        name={props.name}
        row
        value={props.value}
        onChange={handleRadioChange}>
        {Array.from({length: 7}, (_, i) => i + 1).map((i) =>
          <FormControlLabel key={i} value={i} control={<Radio/>}
                            label={i} labelPlacement="bottom"/>,
        )}
      </RadioGroup>
      <FormHelperText>{error ? 'Please select an option.' : ''}</FormHelperText>
    </FormControl>
  );
}