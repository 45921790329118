import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {useState} from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import {saveAs} from 'file-saver';
import SubmitDialog from './SubmitDialog';
import {Outlet} from 'react-router-dom';
import shuffle from './MusicOrderShuffler';

function Group2Form(props) {
  const [formFields, setFormFields] = useState({
    'section1_piece1': '',
    'section1_piece2': '',
    'section1_piece3': '',
    'section1_piece4': '',
    'section2_piece1': '',
    'section2_piece2': '',
    'section2_piece3': '',
    'section3_piece1': '',
    'section3_piece2': '',
    'section3_piece3': '',
    'section3_piece4': '',
  });
  const [dialogStatus, setDialogStatus] = useState({
    'open': false,
    'status': 'incomplete',
  });

  const handleDialogClose = () => {
    setDialogStatus({
      'open': false,
      'status': dialogStatus.status,
    });
  };

  const changeFormFields = (event) => {
    const {name, value} = event.target;
    setFormFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const saveJson = () => {
    let jsonData = {};
    props.section1.forEach((e, i) => {
      jsonData[e] = formFields['section1_piece' + ((i + 1).toString())];
    });
    props.section2.forEach((e, i) => {
      jsonData[e] = formFields['section2_piece' + ((i + 1).toString())];
    });
    props.section3.forEach((e, i) => {
      jsonData[e] = formFields['section3_piece' + ((i + 1).toString())];
    });
    saveAs(new Blob([JSON.stringify(jsonData, null, 2)],
      {type: 'application/json'}), 'form_result_' + Date.now().toString());
  };

  const onSubmit = () => {
    if (Object.values(formFields).includes('')) {
      setDialogStatus({
        'open': true,
        'status': 'incomplete',
      });
    } else {
      setDialogStatus({
        'open': true,
        'status': 'complete',
      });
      saveJson();
    }
  };

  const getSection1PieceComponent = (i, formValue) => {
    return <div key={i.toString()}>
      <Stack direction="column" justifyContent="center" alignItems="center"
             spacing={2}>
        <Stack direction={{xs: 'column', sm: 'row'}} justifyContent="center"
               spacing={2}>
          <audio controls>
            <source src={props.section1[i] + '_1.ogg'} type="audio/ogg"/>
            Your browser does not support the audio element.
          </audio>
          <audio controls>
            <source src={props.section1[i] + '_2.ogg'} type="audio/ogg"/>
            Your browser does not support the audio element.
          </audio>
        </Stack>
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Typography variant="body1">{props.section1[i] + '_1'}</Typography>
          <Typography variant="body1">{props.section1[i] + '_2'}</Typography>
        </Stack>
        <TextField
          label="Your description"
          multiline
          maxRows={3}
          fullWidth
          error={!formValue}
          required
          helperText={!formValue && 'Cannot be empty.'}
          name={'section1_piece' + ((i + 1).toString())}
          value={formValue}
          onChange={changeFormFields}
        />
      </Stack>
    </div>;
  };

  const getSection2PieceComponent = (i, formValue) => {
    return <div key={i.toString()}>
      <Stack direction="column" justifyContent="center" alignItems="center"
             spacing={2}>
        <audio controls>
          <source src={props.section2[i] + '.ogg'} type="audio/ogg"/>
          Your browser does not support the audio element.
        </audio>
        <Typography variant="body1">{props.section2[i]}</Typography>
        <TextField
          label="Your description"
          multiline
          maxRows={3}
          fullWidth
          error={!formValue}
          required
          helperText={!formValue && 'Cannot be empty.'}
          name={'section2_piece' + ((i + 1).toString())}
          value={formValue}
          onChange={changeFormFields}
        />
      </Stack>
    </div>;
  };

  const getSection3PieceComponent = (i, formValue) => {
    return <div key={i.toString()}>
      <Stack direction="column" justifyContent="center" alignItems="center"
             spacing={2}>
        <audio controls>
          <source src={props.section3[i] + '.ogg'} type="audio/ogg"/>
          Your browser does not support the audio element.
        </audio>
        <Typography variant="body1">{props.section3[i]}</Typography>
        <TextField
          label="Your description"
          multiline
          maxRows={3}
          fullWidth
          error={!formValue}
          required
          helperText={!formValue && 'Cannot be empty.'}
          name={'section3_piece' + ((i + 1).toString())}
          value={formValue}
          onChange={changeFormFields}
        />
      </Stack>
    </div>;
  };

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={8}>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={4}>
        <Typography variant="h3" component="div"
                    style={{alignSelf: 'center'}}>
          Experiment Title
        </Typography>
        <Typography variant="body1" component="div" color="#888888">
          Some explanations for this experiment...
        </Typography>
        <Typography variant="subtitle1" component="div">
          Estimated time: x minutes
        </Typography>
      </Stack>
      <Divider/>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}>
        <Typography variant="h4" component="div"
                    style={{alignSelf: 'center'}}>
          Section 1
        </Typography>
        <Typography variant="subtitle1" component="div">
          Listen to 4 pairs of string quartet, then write down
          how would you describe the sound/timbre you heard?
        </Typography>
        {getSection1PieceComponent(0, formFields.section1_piece1)}
        {getSection1PieceComponent(1, formFields.section1_piece2)}
        {getSection1PieceComponent(2, formFields.section1_piece3)}
        {getSection1PieceComponent(3, formFields.section1_piece4)}
      </Stack>
      <Divider>Section 1 Finished</Divider>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}>
        <Typography variant="h4" component="div"
                    style={{alignSelf: 'center'}}>
          Section 2
        </Typography>
        <Typography variant="subtitle1" component="div">
          Listen to 3 pieces of string quartet with
          extended techniques and describe each
        </Typography>
        {getSection2PieceComponent(0, formFields.section2_piece1)}
        {getSection2PieceComponent(1, formFields.section2_piece2)}
        {getSection2PieceComponent(2, formFields.section2_piece3)}
      </Stack>
      <Divider>Section 2 Finished</Divider>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}>
        <Typography variant="h4" component="div"
                    style={{alignSelf: 'center'}}>
          Section 3
        </Typography>
        <Typography variant="subtitle1" component="div">
          Listen to 4 pieces of standard music and describe each
        </Typography>
        {getSection3PieceComponent(0, formFields.section3_piece1)}
        {getSection3PieceComponent(1, formFields.section3_piece2)}
        {getSection3PieceComponent(2, formFields.section3_piece3)}
        {getSection3PieceComponent(3, formFields.section3_piece4)}
      </Stack>
      <Divider>Section 3 Finished</Divider>
      <Button variant="contained" endIcon={<SendIcon/>} size="large"
              color="secondary"
              onClick={onSubmit}>
        Submit
      </Button>
      <SubmitDialog open={dialogStatus.open} status={dialogStatus.status}
                    onClose={handleDialogClose}/>
    </Stack>
  );
}

export default function Group2FormPage() {
  const section1AudioArray = [
    'section1_pair1',
    'section1_pair2',
    'section1_pair3',
    'section1_pair4'];
  const section2AudioArray = [
    'section2_piece1',
    'section2_piece2',
    'section2_piece3'];
  const section3AudioArray = [
    'section3_piece1',
    'section3_piece2',
    'section3_piece3',
    'section3_piece4'];

  let shuffledSection1AudioArray = shuffle(section1AudioArray);
  let shuffledSection2AudioArray = shuffle(section2AudioArray);
  let shuffledSection3AudioArray = shuffle(section3AudioArray);

  return (
    <Container maxWidth="md" style={{marginTop: '36px', marginBottom: '36px'}}>
      <Group2Form
        section1={shuffledSection1AudioArray}
        section2={shuffledSection2AudioArray}
        section3={shuffledSection3AudioArray}/>
      <Outlet/>
    </Container>
  );
}